<template>
	<div class="home" :class="spmenuon + ' ' + scrollClass">
		<div id="wrapAll">
			<header class="header index_mv" role="navigation">
				<h1 class="header_logo" style="display: flex; align-items: center; width: 320px">
					<router-link to="/">
						<!-- <img class="header_logo_img sp" src="../assets/img/xintp/logo.png" alt="">
                            <img class="header_logo_img pc" src="../assets/img/xintp/logo.png" alt=""> -->
						<img class="header_logo_img pc" src="../assets/img/xintp/index-logo.png" alt="" />
					</router-link>
					<!-- <router-link to="/" style="padding-left: 10px">
						<img
							class="header_logo_img pc"
							src="../assets/img/xintp/logo-right.png"
							alt=""
							style="height: 40px"
						/>
					</router-link> -->
				</h1>
				<ul class="header_nav ul1 pc" v-if="menus.length" style="display: flex; justify-content: space-between">
					<li class="header_nav_li">
						<router-link to="/" class="header_nav_li_a">首页</router-link>
					</li>
					<li v-for="(li, index) in menus" :key="index" class="header_nav_li">
						<span>
							<router-link class="header_nav_li_a" :to="li.__url" :target="li.__target">{{
								li.title
							}}</router-link>
						</span>
						<dl>
							<dd>
								<label v-if="ls.nav == '1'" @click="nato(ls, index, index1, li.id)"
									v-for="(ls, index1) in li.children" :key="index1">{{ ls.title }}</label>
							</dd>
						</dl>
					</li>
				</ul>

				<!-- <div class="searchsy" v-if="serx">
					<input type="text" @keydown="keydown" v-model="searchkeyword" placeholder="SEARCH" />
					<img @click="onSearch" src="../assets/img/xintp/ser.png" alt="" />
				</div> -->

				<a id="js-spmenu" class="header_a_spmenu" style="margin: 10px 2px" href="javascript:void(0);"
					@click="openM"><span class="header_a_spmenu_span"></span></a>
				<!-- <a
					style="right: 45px; top: 47%"
					class="header_a_spmenu header_a_spmenu1"
					@click="serx = serx ? false : true"
					><img src="../assets/img/xintp/hicetea3.png" alt=""
				/></a> -->
			</header>
		</div>
		<div class="header_spnav" id="js-spmenuarea">
			<ul class="header_spnav_ul" @click="spmenuon = ''" v-if="menus.length">
				<li class="header_spnav_ul_li">
					<router-link to="/" class="header_spnav_ul_li_a">首页</router-link>
				</li>
				<li v-for="(li, index) in menus" :key="index" class="header_spnav_ul_li">
					<span><router-link :to="li.__url" class="header_spnav_ul_li_a">{{ li.title }}</router-link></span>
				</li>
			</ul>
		</div>
		<router-view @getLoad="showMsg" />
		<div class="foote">
			<div class="read_articles">
				<Dropdown placement="bottom-end">
					<a href="javascript:void(0)">
						中国高校档案网站
						<Icon type="md-arrow-dropdown" />
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="(li, index) in link1" :key="index">
							<a :href="li.url" target="_blank">{{ li.name }}</a>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
				<Dropdown placement="bottom-end">
					<a href="javascript:void(0)">
						国内档案网站
						<Icon type="md-arrow-dropdown" />
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="(li, index) in link2" :key="index">
							<a :href="li.url" target="_blank">{{ li.name }}</a>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
				<Dropdown placement="bottom-end">
					<a href="javascript:void(0)">
						国外档案网站
						<Icon type="md-arrow-dropdown" />
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="(li, index) in link3" :key="index">
							<a :href="li.url" target="_blank">{{ li.name }}</a>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
				<Dropdown placement="bottom-end">
					<a href="javascript:void(0)">
						校内站点
						<Icon type="md-arrow-dropdown" />
					</a>
					<DropdownMenu slot="list">
						<DropdownItem v-for="(li, index) in link4" :key="index">
							<a :href="li.url" target="_blank">{{ li.name }}</a>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</div>
		</div>
		<footer class="footes">
			<div class="read_articles">
				<img src="../assets/img/xintp/bot-logo.png" alt="" style="width: 300px; height: 60px" />
				<div class="border-e"></div>
				<div class="footli">
					<img src="../assets/img/xintp/bot2.png" alt="" style="width: 28px; height: 28px" />
					<div class="footli-title" style="display: flex; align-items: center">
						<div class="footli-title-content">
							<p>联系我们：</p>
							<div>南方科技大学一丹图书馆一楼文博中心（档案校史馆）</div>
							<div>电话:（0755）88010230</div>
							<div>传真:（0755）88012921</div>
							<div>邮编： 518055</div>
						</div>
						<!-- 广东省深圳市南山区学苑大道1088号<span class="footer_address_tel">电话: +86-755-88010114</span><span
                            class="footer_address_tel">邮编:518055</span> -->
						<div class="img" style="padding-top: 20px; padding-left: 30px">
							<img src="../assets/img/xintp/qr.jpg" alt="" style="width: 72px; height: 72px" />
						</div>
					</div>
				</div>
				<div class="border-e"></div>
				<div class="links">
					<img src="../assets/img/xintp/bot3.png" alt="" style="width: 28px; height: 28px" />
					<div v-if="menus.length">
						<p>快速导航：</p>
						<div style="display: flex;">
							<div><router-link to="/">首页</router-link></div>
							<div v-for="(li, index) in menus" :key="index">
								<div class="footer_address_tel">
									<router-link :to="li.__url" :target="li.__target">{{ li.title }}</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<footer class="footews">
			<div class="read_articles copyright">
				<div class="copyright-title" style="color:#666">
					{{ config.copyright }}
				</div>
				<div class="record" style="color:#666">
					{{ config.record }}
				</div>
			</div>
		</footer>
		<div class="fx-menu">
			<ul class="fx-menu_ul">
				<li class="fx-menu_ul_li">
					<router-link to="/" class="fx-menu_ul_li_a">首页</router-link>
				</li>
				<li v-for="(li, index) in menus" :key="index" class="fx-menu_ul_li">
					<span><router-link :to="li.__url" class="fx-menu_ul_li_a" :target="li.__target">{{
						li.title
					}}</router-link></span>
					<dl>
						<dd>
							<label v-if="ls.nav == '1'" @click="nato(ls, index, index1, li.id)"
								v-for="(ls, index1) in li.children" :key="index1">{{ ls.title }}</label>
						</dd>
					</dl>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import QueryString from 'qs';
export default {
	name: 'index',
	data() {
		return {
			searchkeyword: '',
			spmenuon: '',
			scrollTop: '',
			scrollClass: '',
			act: -1,
			links: [],
			link1: [],
			link2: [],
			link3: [],
			link4: [],
			config: [],
			quick: [],
			serx: false
		};
	},
	created() {
		this.$api
			.nav({ level: this.$store.state.navIndexs.length })
			.then(res => {
				let findChild = (row, level, levelMax = 2) => {
					if (level < levelMax && row.children && row.children.length > 0) {
						for (let x in row.children) {
							if (row.children[x].type != 'link') {
								return findChild(row.children[x], ++level, levelMax);
							}
						}
					}
					return row;
				};
				let handleUrl = (id, params, item, levelMax = 2) => {
					let temp = findChild(item, 0, levelMax);
					//console.warn(temp)
					params.catid = temp.id;
					params.type = temp.type;
					item.__target = '_self';
					if (params.type == 'link') {
						item.__url = temp.jump;
						item.__target = '_blank';
					} else {
						let url = '';
						if (
							(item.children && params.type == 'news') ||
							params.type == 'news' ||
							params.type == 'newscategory'
						) {
							url = '/bggk/listGk/';
						} else if (params.type == 'form') {
							delete params.type;
							url = '/fwdt/yyjyF/';
						} else if (params.type == 'page') {
							params.show_tile = temp.is_show_title;
							params.page = 1;
							url = '/details/text/';
						}
						item.__url = url ? url + id + '?' + QueryString.stringify(params) : '';
					}
				};
				let navList = res.data;
				navList.map((item, index) => {
					//if(!item.__url){
					let params = {
						index: index,
						pid: item.id,
						sx: 0
					};
					handleUrl(item.id, params, item);
					//console.log(item.__url,item.title);
					if (item.children) {
						item.children.map((item2, index2) => {
							params.sx = index2;
							handleUrl(item2.id, params, item2, 1);
							//console.log(item2.__url,item2.title);
						});
					}
					//}
					//console.log(item)
				});
				this.$store.dispatch('save', ['navList', navList]);
				this.handleQueryParams();
			})
			.catch(error => {
				console.log(error);
			});
		/**
		 * 读取底部选择框数据
		 * 1 中国高校档案网站 2 国内档案网站 3 国外档案网站 4 校内站点
		 */
		this.$api
			.links({ type: [1, 2, 3, 4] })
			.then(res => {
				for (let x in res.data) {
					this['link' + x] = res.data[x];
				}
			})
			.catch(error => {
				// error
				console.log(error);
			});

		this.$api
			.category({
				fid: 6
			})
			.then(res => { })
			.catch(error => { });
		this.$api
			.home()
			.then(res => {
				this.$emit('getMessage', true);
				this.quick = res.quick;
				this.links = res.links;
				this.config = res.config;
			})
			.catch(error => {
				// error
				console.log(error);
			});

		window.addEventListener('scroll', this.scrollToTop);
	},
	computed: {
		menus() {
			let { navList } = this.$store.state;
			return navList;
		}
	},
	watch: {
		$route: {
			handler: function (val) {
				this.handleQueryParams();
			},
			// 深度观察监听
			deep: true
		}
	},
	methods: {
		handleQueryParams() {
			let query = this.$route.query;
			let navList = this.$store.state.navList;
			let { navIndexs } = this.$store.state;
			navIndexs[0] = -1;
			navList.map((item, index) => {
				item._current = item.id == query.pid;
				if (item._current) {
					navIndexs[0] = index;
				}
				return item;
			});
			this.$store.dispatch('save', ['navList', navList]);

			let active = query.sx || 0;
			let current = [];
			if (navIndexs[0] >= 0) {
				current = navList[navIndexs[0]].children;
				navIndexs[1] = active < current.length ? active : 0;
			}
			//console.log(navIndexs);
			//this.$store.dispatch('save', ['navIndexs', navIndexs]);
		},
		nato4(e) {
			var that = this;
			that.active = e;

			that.$router.push('/daxt/newsd/' + e);
		},
		nato3(e) {
			var that = this;
			that.active = e;
			that.$router.push('/slzj/lists/' + e);
		},
		nato2(e) {
			var that = this;
			that.active = e;
			that.$router.push('/gdzn/newsg/' + e);
		},
		nato1(e) {
			var that = this;
			that.active = e;
			that.$router.push('/gzzd/listgz/' + e);
		},
		nato(e, index, index1, id) {
			if (e.__target != '_blank') {
				this.$router.push(e.__url);
			} else {
				window.open(e.__url, '_blank');
			}
			return;
			var that = this;
			if (e.type == 'newscategory') {
				that.$api
					.category({
						fid: e.fid
					})
					.then(res => {
						if (res.data.length) {
							that.$router.push(
								'/bggk/listGk/' +
								res.data[0].id +
								'?index=' +
								index +
								'&pid=' +
								id +
								'&sx=' +
								index1 +
								'&catid=' +
								e.catid +
								'&type=' +
								e.type
							);
						} else {
							that.$router.push(
								'/bggk/listGk/' +
								res.id +
								'?index=' +
								index +
								'&pid=' +
								id +
								'&sx=' +
								index1 +
								'&catid=' +
								e.catid +
								'&type=' +
								e.type
							);
						}
					})
					.catch(error => {
						// error
						console.log(error);
					});
			}
			// that.active = e
			if (e.type == 'form') {
				that.$router.push('/fwdt/yyjyF/' + e.id + '?index=' + index + '&pid=' + id + '&sx=' + index1);
			}
			if (e.type == 'news') {
				that.$router.push(
					'/bggk/listGk/' +
					e.id +
					'?index=' +
					index +
					'&pid=' +
					id +
					'&sx=' +
					index1 +
					'&catid=' +
					e.catid +
					'&type=' +
					e.type
				);
			}

			if (e.type == 'page') {
				that.$router.push(
					'/details/text/' +
					e.id +
					'?index=' +
					index +
					'&pid=' +
					id +
					'&sx=' +
					index1 +
					'&page=1&catid=' +
					e.id +
					'&type=page&show_tile=' +
					e.is_show_tile
				);
			}

			// if (e == 6) {
			// 	that.$router.push('/bggk/ggjj/' + e)
			// }
			// if (e == 9 || e == 8) {
			// 	that.$router.push('/bggk/listGk/' + e)
			// }
			// if (e == 7) {
			// 	that.$router.push('/bggk/sjbb/' + e)
			// }
			// if (e == 14 || e == 15) {
			// 	that.$router.push('/zszx/listz/' + e)
			// }

			// if (e == 16 || e == 17) {
			// 	that.$router.push('/zszx/newsz/' + e)
			// }
			// if (e == 30 || e == 32) {
			// 	that.$router.push('/fwdt/textF/' + e)
			// }
			// if (e == 31) {
			// 	that.$router.push('/fwdt/yyjyF/' + e)
			// }
			// if (e == 33) {
			// 	that.$router.push('/fwdt/tableF/' + e)
			// }
		},
		showMsg(val) {
			// methods方法  val即为子组件传过来的值
			var that = this;
			that.$emit('getMessage', true);
		},
		keydown(event) {
			var that = this;
			console.log(event);
			if (event.which == 13) {
				that.onSearch();
			}
		},
		onSearch() {
			var that = this;
			if (!that.searchkeyword) {
				that.$Message.error('请输入搜索词！');
				return;
			}
			that.serx = false;
			that.$router.push({
				name: 'search',
				params: {
					searchtext: that.searchkeyword
				}
			});
		},
		scrollToTop() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			if (scrollTop > 160) {
				this.scrollClass = 'js-pgload fx-scroll';
			} else {
				this.scrollClass = '';
			}
		},
		openM() {
			var that = this;
			if (that.spmenuon == 'js-spmenuon') {
				that.spmenuon = '';
			} else {
				that.spmenuon = 'js-spmenuon';
			}
		}
	}
};
</script>
<style>
@media only screen and (max-width: 800px) {
	.header_nav {
		display: none !important;
	}

	.fx-scroll .header {
		background: #26b6b4 !important;
	}

	.copyright-title,
	.record {
		font-size: 14px !important;
	}

	.record {
		border-left: none !important;
		padding-left: 0px !important;
		margin-left: 0px !important;
	}
}

.fx-scroll .header {
	background: none;
}

.record {
	border-left: 1px solid #999;
	padding-left: 20px;
	margin-left: 20px;
	font-size: 16px;
}

.copyright-title {
	font-size: 16px;
}

.header_nav_li {
	/* padding: 0 18px; */
	padding: 0;
}
</style>
